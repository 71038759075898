import React, { useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgBelgium_Contactpage from '../../assets/ContactLocation/BelgiumStage.png'
import imgLIS from "../../assets/ContactLocation/LIS.png"

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const units = [
        {
            name: 'L.I.S.-Hydraulics',
            location: 'L.I.S.-Hydraulics, Eikelaarstraat 19, 3600 Genk, Belgium',
            telephone: '+32 89 35 97 16',
            mail: 'info@lishydraulics.be',
            image: imgLIS
        },

    ];

    return (
        <div className="">
            <div>
                <CenterStage
                    imageSrc={imgBelgium_Contactpage}
                    title={"Get in touch with L.I.S.-Hydraulics"}
                    paragraph={"Dive into a world of hydraulic expertise and personalised service. Contact us today and see how L.I.S.-Hydraulics can take your hydraulic operations to new heights."} />
            </div>
            <div className=''>
                <ContactUsCard units={units} />
            </div>
        </div>
    );
};

export default ContactUs;

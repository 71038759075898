import React, { useEffect } from 'react';
import FullPageCarousel from '../Components/Carousel/FullPageCarousel';
import ImageCard from '../Components/ImageCard/ImageCard';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './PageCss/HomePage.css';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import CenterStage from '../Components/CenterStage/CenterStage';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook


import imgHose from '../assets/hose.jpg';
import imgCarton from '../assets/Hansa-Flex_Kartons_Shop.png';

import Promoter from '../Components/Promoter/Promoter';
import FeatureCardCarousel from '../Components/FeaturesCardCarousal/FeatureCardCarousal'
import AOS from 'aos';
import 'aos/dist/aos.css';
import circleCheck from '../assets/check-circle.svg'
import imgIndustriesServedBelgium from '../../src/assets/IndustriesServedBelgium.png'
import Horizontal from '../Components/Horizontal/Horizontal';
import imgHome_BelgiumPipes from '../assets/Home_BelgiumPipes.jpg';
import imgHomeBelgium_driveandcontroltechnology from '../assets/HomeBelgium_driveandcontroltechnology.jpg';
import imgHomeBelgium_INDHOSES from '../assets/HomeBelgium_INDHOSES.jpg';
import imgHomeBelgium_Adaptar from '../assets/HomeBelgium_Adaptar.jpg';
import imgHomeBelgium_Scan from '../assets/HomeBelgium_Scan.jpg';
import imgHomeBelgium_Cylinder from '../assets/HomeBelgium_Cylinder.jpg'
import imgHomeBelgium_Xcode from '../assets/HomeBelgium_Xcode.jpg';
import imgHomeBelgium_Indinspection from '../assets/HomeBelgium_Indinspection.jpg';
import imgHomeBelgium_Cylinderrepair from '../assets/HomeBelgium_Cylinderrepair.jpg';
import imgbestquality from '../assets/bestquality.png';
import imgFlexibleassemblyteam from '../assets/Flexibleassemblyteam.png';
import imgXcode from '../assets/Xcode.png';
import imgWarehouse from '../assets/Warehouse.png';
import imgLISpneumatics from '../assets/Products/LISpneumatics.jpg';
import imgLISindustrialhose from '../assets/Products/LISindustrialhose.jpg';
import LISPipelines from '../assets/Products/LISPipelines.jpg';
import imgLISLISCustomiseassembly from '../assets/Services/LISCustomiseassembly.jpg'
import imgLISHydrauliccylinderrepair from '../assets/Services/LISHydrauliccylinderrepair.jpg';
import imgLISMobile from '../assets/Services/LISMobile.jpg';
import imgLISOilfilering from '../assets/Services/LISOilfilering.jpg';
import imgtechnician from '../assets/technician.png';
import imageTraining2 from '../assets/imageTraining2.jpg';
import imgStudyDesignMaintainance from '../assets/Services/StudyDesignMaintainance.jpg'
import imgHydraulicinstallation from '../assets/Services/Hydraulicinstallation.jpg'
import imgCounterSales from '../assets/Products/CounterSales.jpg'
import imgSellcomponents from '../assets/Products/Sellcomponents.jpg'
import imgHydraulicCylinder from '../assets/Products/DriveAndControlTechnology/Cylinders/Stage.jpg'

function HomePage() {

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 320, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 300, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  const featuresData = [
    {
      title: "All under one roof",
      description: "Benefit from a complete range of hydraulic hoses and connecting elements for the hydraulics.",
      image: imgWarehouse
    },
    {
      title: "Highly trained service technicians ",
      description: "Our technicians carry out assembly and installation work in hydraulic systems.",
      image: imgtechnician
    },
    // {
    //   title: "X-CODE",
    //   description: "Reduce unplanned machine downtime to a minimum with the X-CODE.",
    //   image: imgXcode
    // },

    {
      title: "Best quality",
      description: "Certified according to DIN EN ISO 9001: 2015, we stand for the highest quality standards.",
      image: imgbestquality
    },

    // Add more objects for additional FeaturesCard data
  ];

  //promoter content

  const promoterTitle = 'PLACE YOUR ORDER TODAY!';
  const promoterChecklistItems = [
    'The full HANSA‑FLEX product range with more than 80,000 articles.',
    'Category-specific filter parameters enable quick navigation.',
    'Please call us at +32 89 35 97 16 for availability.',
    <>Place your order at <a href="mailto:info@lishydraulics.be">info@lishydraulics.be</a>.</>
  ];
  const promoterButtonLabels = ['CONTACT US', 'FIND OUT MORE !'];

  const googleReviewsLink = "https://www.google.com/search?q=lis+hydraulics&rlz=1C1GCEU_enIN1077IN1077&oq=lis+hydraulics+&gs_lcrp=EgZjaHJvbWUyCggAEEUYFhgeGDkyBwgBEAAYgAQyCAgCEAAYFhgeMgYIAxBFGDwyBggEEEUYPDIGCAUQRRg80gEJMTIzOTlqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x47c0df00f21bd507:0xbfb0c408ac834312,1,,,,";

  return (
    <div>
      <FullPageCarousel />

      <SectionHeader
        title="Our Products"
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={6} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHydraulicinstallation} learnMoreLink="/Hydraulicinstallation" title="HYDRAULIC INSTALLATIONS" desc="One of the specialities of L.I.S.-Hydraulics is the design, construction and start-up of complete hydraulic systems." />
          </Col>

          {/* <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgSellcomponents} learnMoreLink="/Sellcomponents" title="SALES HYDRAULIC COMPONENTS" desc="L.I.S.-Hydraulics can significantly increase the efficiency and productivity of the customer's processes by advising them on the choice of the right hydraulic components, which will minimize their production downtime." />
          </Col>

          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgCounterSales} learnMoreLink="/Countersales" title="COUNTER SALES" desc="L.I.S.-Hydraulics has a large stock of hydraulic and pneumatic components available to help customers as quickly as possible." />
          </Col> */}

          <Col sm={12} md={6} lg={6} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHydraulicCylinder} learnMoreLink="/cylinder" title="HYDRAULIC CYLINDERS" desc="L.I.S.-Hydraulics: Production, Revision and Renovation. Both standard and customer-specific cylinders." />
          </Col>

          {/* <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgLISpneumatics} learnMoreLink="/pneumatics" title="USING PNEUMATICS TO MOVE, CONTROL AND POSITION" desc="Pneumatics are at the heart of automated processes in logistics centres, small parts warehouses and the automotive industry. Compressed air is also used in vacuum technology and is indispensable for digital lifting and positioning operations. The quality and design of the components used make a crucial difference to the productivity and efficiency of pneumatic systems. HANSA‑FLEX offers effective solutions and customised services." />
          </Col> */}
          {/* <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={LISPipelines} learnMoreLink="/pipelines" title="YOUR SPECIALIST FOR EVERYTHING TO DO WITH PIPELINES" desc="The HANSA‑FLEX range of pipeline products includes expansion joints, bent pipes and special fittings for liquids, gases and coolant water. HANSA‑FLEX’s state-of-the-art manufacturing technologies ensure absolute precision, the highest quality and rapid production to meet the customer’s specifications exactly. HANSA‑FLEX sets new standards in connection technology with its own innovative pipe-forming system HF-Form, which is soon to become available.." />
          </Col> */}
          {/* <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHomeBelgium_Cylinder} learnMoreLink="/cylinder" title="CYLINDERS" desc="Hydraulic cylinders perform extremely heavy work. As the most important drive elements in hydraulics, they convert the pressure energy of the hydraulic fluid into mechanical energy..." />
          </Col> */}

        </Row>
      </Container>

      <SectionHeader
        title="Our Services"
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={6} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgStudyDesignMaintainance} learnMoreLink="/StudyDesignandMaintenace" title="STUDY, DESIGN AND MAINTENANCE" desc="L.I.S.-Hydraulics assists customers in developing new hydraulic projects or converting existing installations." />
          </Col>
          <Col sm={12} md={6} lg={6} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imageTraining2} learnMoreLink="/training" title="TRAINING" desc="Employee training is a constantly topical and indispensable issue, especially in the world of technology." />
          </Col>
          {/* <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLISMobile} learnMoreLink="/mobile-rapid-hydraulic-service" title="MOBILE HYDRAULICS" desc="When hydraulic components fail unexpectedly, quick action is needed. With 180 vehicles throughout Germany, our Rapid Hydraulic Service mobile is always at your disposal, available 24/7, 365 days a year, to minimize downtime." />
          </Col>
          <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgLISOilfilering} learnMoreLink="/oil-filtering-and-cleaning" title="OIL FILTERING AND CLEANING" desc="Solid and liquid contaminants cause abrasion, increase wear and promote corrosion. Regular oil filtration ensures your production processes run smoothly and helps avoid damage to equipment, systems and machines." />
          </Col>
          <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLISLISCustomiseassembly} learnMoreLink="/Customisedassembly" title="CUSTOMISED ASSEMBLY" desc="We preassemble customised hydraulic hose lines from samples and to suit customer specific requirements for low, medium and high-pressure applications with the associated fittings in our branches." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgLISHydrauliccylinderrepair} learnMoreLink="/hydraulic-cyclinder-repair" title="CYLINDER REPAIR" desc="Hydraulic cylinders are subject to extreme stresses due to their continual extending and retracting. The result is wear, which puts the whole working process at risk of failure. Overhauling a cylinder is a good alternative to buying a new one." />
          </Col> */}

        </Row>
      </Container>



      {/* <div>
        <SectionHeader
          title="Our Services"
        /> */}
      {/* 
        <Container className="custom-container">
          <Row> */}




      {/* </Row>
        </Container>


      </div> */}


      {/* <div>
        <SectionHeader
          title="Explore our units!"
          paragraph="We have our units in Pune, which provide extensive range of products!"
        />
        <ShowcaseCarousel carouselItems={carouselItems} descDisplay={isMobile ? 'none' : 'block'} />
      </div>
      <br /><br /><br /> */}

      <div className=''>
        <SectionHeader
          title="Premium Hydraulics & Pneumatics Solutions"
          subtitle="Select from more than 80,000 articles and always find the right product for your needs. We stock everything from A-Z."
        />
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div>
        {isLaptop && (
          <Promoter
            title={promoterTitle}
            checklistItems={promoterChecklistItems}
            btnLabel1={"Find Out More !"}
            btnLabel1Link={"https://shop.hansa-flex.be/nl_NL/slangen/c/webcat_HF_SCH"}
            imageUrl={imgCarton}
          />
          // TODO: contact us button
        )}
      </div>


      <div className="text-center">
        <SectionHeader
          title={"Check out our reviews on google!"}
          paragraph={"Curious about what others have to say about us? Take a moment to explore our Google reviews and discover why our customers rave about their experiences with us. "} />
        <br />
        <Button variant="primary" href={googleReviewsLink} target="_blank" className="btn-google" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>View our reviews on Google</Button>

        <br /><br /><br />
      </div>

      {/* <div>
        <SectionHeader
          title="News and Events" />
        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={8} data-aos="fade-up" ><ImageCard image={imgIree} learnMoreLink={'/iree'} title="HANSA-FLEX at IREE" desc={"HANSA-FLEX India is thrilled to have participated in the 15th edition of the International Railway Equipment Exhibition (IREE), which took place from October 12 to 14, 2023, at Pragati Maidan, New Delhi. IREE stands as Asia's largest platform dedicated to the Rail Transportation Sector, and this event was organized by the Confederation of Indian Industry (CII) in collaboration with the Ministry of Railways, Government of India."} /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgBauma} learnMoreLink={'/bauma'} title="Bauma Conexpo India 2023 " desc="The 6th edition of international trade fair Bauma Conexpo India 2023, construction machinery event was held in Greater Noida, from Jan 31st -3rd Feb, 2023. " /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgTree} learnMoreLink={'/tree-plantation'} title="Tree Plantation on Environment Day" desc="HANSA-FLEX India Pvt Ltd proudly celebrates Environment Day by organizing a tree plantation event, acknowledging the importance of environmental conservation and sustainability. The event is scheduled to take place on the 5th of June 2023, from 9:00 am to 11:00 am" /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgGarry} learnMoreLink={'/product-training'} title="Product training session taken by Gary Howes" desc="Join us for an extensive training session on Hose Assembly, Fittings, and Welding Products presented by Gary Howes. Taking place from the 4th to the 14th of July 2023 at HANSA-FLEX (IFP) INDIA PVT LTD, this comprehensive training program will cover a wide range of topics essential for professionals in the industry." /></Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up"><ImageCard image={imgGoetheVisit} learnMoreLink={'/goethe-event'} title="Empowering Students: Insights into Career Opportunities and Language Skills" desc="Join us for an extensive training session on Hose Assembly, Fittings, and Welding Products presented by Gary Howes. Taking place from the 4th to the 14th of July 2023 at HANSA-FLEX (IFP) INDIA PVT LTD, this comprehensive training program will cover a wide range of topics essential for professionals in the industry." /></Col>
          </Row>
        </Container>
      </div> */}


      <div>
        <SectionHeader title={"Industries Served"} />
        <div className="home-imageContainer">
          {isMobile ? <>
            <img src={imgIndustriesServedBelgium} alt="Description of the image" className='home-img' />

          </> : <>
            <img src={imgIndustriesServedBelgium} alt="Description of the image" className='home-img' />

          </>}
        </div>
      </div>



      {/* <div className="text-center div-white">
        <SectionHeader
          title={"Check out our reviews on google!"}
          paragraph={"Curious about what others have to say about us? Take a moment to explore our Google reviews and discover why our customers rave about their experiences with us. "} />
        <br />
        <Button variant="primary" href={googleReviewsLink} target="_blank" className="btn-google" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>View our reviews on Google</Button>
      </div> */}

      <div>
        <Horizontal />
      </div>
    </div>

  );
}

export default HomePage;
